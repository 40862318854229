<div role="document">
  <div class="modal-content">

    <!-- modal header -->
    <div class="modal-header bg-primary ">
      <h2 class="modal-title text-white">Confirmation</h2>
    </div>
    <!-- modal header -->
    <div class="modal-body">
      <!-- modal content -->
      <p>{{message}}</p>
      <!-- modal content -->

    </div>
    <div class="modal-footer d-flex"
      [ngClass]="{'justify-content-between':showCancel, 'justify-content-center': !showCancel}">


      <button type="button" class="btn btn-primary" (click)="activeModal.close(false)"
        *ngIf="showCancel">CANCEL</button>
      <button type="button" class="btn btn-primary" (click)="activeModal.close(true)">OK</button>


    </div>
  </div>
</div>

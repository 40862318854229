<div class="row">
  <div class="col-sm-5">
    <h2 class=" text-dark font-weight-bold">{{contract?.contract_title}} to sign</h2>
  </div>
</div>
<br>
<div>
  <div>
    <pdf-viewer [src]="contract?.pdfUrl" [render-text]="true" style="display: block;"></pdf-viewer>
  </div>
  <div *ngIf="!contract?.signature_date" class="d-flex justify-content-between">
    <div class="custom-control custom-checkbox added-btn pl-5 pb-2 pr-5 pt-2 rounded d-flex">
      <input type="checkbox" class="custom-control-input" id="defaultUnchecked" [checked]="signed" [(ngModel)]="signed">
      <label class="custom-control-label" for="defaultUnchecked">
        <span class="text-dark-orange font-weight-bold">I agree with all the terms of the contract</span>
      </label>
    </div>

    <div>
      <button type="button" class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        [disabled]="!signed" (click)="saveData()">Validate</button>
    </div>

  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.css"],
})
export class PaginationComponent implements OnInit {
  @Input() totalItems: number = 0;
  @Input() pageSize: number = 10;
  @Output() pageClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() currentPage: number = 1;
  public totalPages: number;
  public startIndex: number;
  public endIndex: number;
  public pages: any = [];

  constructor() { }

  setPage(pageNo) {
    this.pageClicked.emit(pageNo);
    this.currentPage = pageNo;
    this.initPager();
  }

  ngOnInit(): void {
    this.initPager();
  }

  ngOnChanges() {
    console.log("Inside ngOnChanges");
    this.initPager();
  }

  initPager() {
    console.log("Init page", this.totalItems, this.pageSize);
    // calculate total pages
    this.totalPages = Math.ceil(this.totalItems / this.pageSize);

    let startPage: number, endPage: number;
    if (this.totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = this.totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (this.currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (this.currentPage + 4 >= this.totalPages) {
        startPage = this.totalPages - 9;
        endPage = this.totalPages;
      } else {
        startPage = this.currentPage - 5;
        endPage = this.currentPage + 4;
      }
    }

    // calculate start and end item indexes
    this.startIndex = (this.currentPage - 1) * this.pageSize;
    this.endIndex = Math.min(
      this.startIndex + this.pageSize - 1,
      this.totalItems - 1
    );

    // create an array of pages to ng-repeat in the pager control
    this.pages = [];
    for (let i = startPage; i < endPage + 1; i++) {
      this.pages.push(i);
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ERROR_MESSAGES } from "src/app/common/constants";
import {
  getById,
  getContractDetails,
  signContract,
} from "src/app/services/contract.service";

@Component({
  selector: "app-view-contract",
  templateUrl: "./view-contract.component.html",
  styleUrls: ["./view-contract.component.scss"],
})
export class ViewContractComponent implements OnInit {
  public contract: any;
  public signed: boolean;
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  loadById = async (id) => {
    this.spinner.show();
    try {
      let { success, result } = await getById(id);
      if (success) {
        this.contract = result;
        this.contract.pdfUrl = await getContractDetails(
          this.contract.contract_description_url.replace(".html", ".pdf"),
          false
        );
      }
    } catch (err) {
      this.toastr.error(
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  ngOnInit(): void {
    this.loadById(this.activatedRoute.snapshot.paramMap.get("id"));
  }

  async saveData() {
    this.spinner.show();
    try {
      await signContract(this.contract.id);
      this.toastr.success("Contract signed successfully");
      this.router.navigate(["/consumer/contracts"]);
    } catch (err) {
      this.toastr.error(
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  }
}

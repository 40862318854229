import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "tableFilter",
})
export class TableFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, columnNames: string[]): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((item) => {
      return columnNames.some((columnName) => {
        return item[columnName].toLowerCase().includes(searchText);
      });
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { DEFAULT_LANGUAGE_TYPE, FRENCH_LANGUAGE } from 'src/app/common/constants';

@Component({
  selector: 'app-signup-progress',
  templateUrl: './signup-progress.component.html',
  styleUrls: ['./signup-progress.component.scss']
})
export class SignupProgressComponent implements OnInit {
  langSelected = 'en';
  constructor() { }

  ngOnInit(): void {
  }

  languageSelected(ln: string) {
    console.log(ln);
    if (ln === DEFAULT_LANGUAGE_TYPE)
      this.langSelected = DEFAULT_LANGUAGE_TYPE;
    if (ln == FRENCH_LANGUAGE)
      this.langSelected = FRENCH_LANGUAGE
  }



}

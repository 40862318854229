<div class="main-produ mt-5 mb-3 d-inline-block w-100">
    <h2 class="font-weight-bold text-dark">{{'products' | translate }} </h2> 
  </div>
  <br>
  <div class="wrapper" *ngIf="!editProduct">
    <div *ngIf="products && products?.length > 0">
      <div class="table-responsive ">
        <div class="force-overflow"></div>
        <table class="table table-bordered table-fixed mb-0">
          <thead class="text-primary">
            <tr>
              <th scope="col" sortable="ean" (sort)="onSort($event)" >EAN</th>
              <th scope="col" sortable="title" (sort)="onSort($event)">{{'title' | translate }}</th>
              <th scope="col">{{'description' | translate }}</th>
              <th class="col4" scope="col">{{'country' | translate }}</th>
              <!-- <th scope="col">Enabled</th>     -->         
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of products;">
              <td  class="cursor-pointer" (click)="goToProduct($event, product.id)"> {{product?.ean}} </td>
              <td  class="cursor-pointer" (click)="goToProduct($event, product.id)"> {{product?.title}} </td>
              <td class="cursor-pointer" (click)="goToProduct($event, product.id)"> {{product?.description}} </td>
              <td class="cursor-pointer" (click)="goToProduct($event, product.id)" > {{product?.country?.code}} </td>
              <!-- <td>
                <div class="custom-control custom-switch ">
                  
                  <input type="checkbox" class="custom-control-input" [checked]="product?.enable" disabled/>
                  <label class="custom-control-label" ></label>
                </div>
              </td> -->
            </tr> 
        </table>
      </div>
  
      <app-pagination [totalItems]="totalPage" [pageSize]="pageCount" (pageClicked)="setPage($event)" [currentPage]="page"
      *ngIf="totalPage > pageCount">
      </app-pagination>
    </div>
    <div *ngIf="!products || products?.length === 0" > {{'no' | translate }} {{'products' | translate }}</div>
  </div>

  <div *ngIf="editProduct">
    <app-edit-product (showProducts)="showList($event)" [product]="product"></app-edit-product>
  </div>

import { ChartConfiguration } from "chart.js";
import { NgcCookieConsentConfig } from "ngx-cookieconsent";

export const SUCCESS_MESSAGES = {
  LOGIN_SUCCESSFUL: "Login Successful !",
  LOGIN_SUCCESSFUL_FR: "Connexion réussie !",
  RESET_PASSWORD_FR: "Votre mot de passe a été réinitialisé avec succès.",
  RESET_PASSWORD: "Your password has been reset successfully.",
  TOKEN_COPIED: "API token copied to clipboard",
  TOKEN_COPIED_ERROR: "Error in copying API token to clipboard",
  API_ADDED: "API Bearer token added successfully",
  API_UPDATED: "API Bearer token updated successfully",
  CONSUMER_UPDATED: "Consumer updated successfully",
  LANGUAGE_SWITCHED: "Language has been switched to %s successfully",
  PRODUCT_UPDATED: "Product updated successfully",

  CODE_SENT: "An email with a verification code was just sent to",
  CODE_SENT_FR: "Un e-mail avec un code de vérification vient dêtre envoyé à",
  SIGNUP_SUCCESS: "You have Successfully Registered",
  SIGNUP_PENDING:
    "Your registration is pending approval. Once approved you can perform this action",
  SIGNUP_PENDING_FR:
    "Votre inscription est en attente d`approbation. Une fois approuvé, vous pouvez effectuer cette action",
  PASSWORD_CHANGED_SUCCESSFULLY: "Password changed Successfully",
};

export const ERROR_MESSAGES = {
  UNKNOWN_SERVER_ERR: "Something went wrong !",
  CHANGE_PASSWORD: "This password is temporary. Please change your password.",
  CHANGE_PASSWORD_FR:
    "Ce mot de passe est temporaire. Veuillez changer votre mot de passe.",
  LANGUAGE_SWITCH: "Language could not be switched. Something went wrong.",
  INVALID_CODE: "Invalid verification code provided, please try again.",
  INVALID_CODE_FR:
    "Code de vérification fourni non valide, veuillez réessayer.",
};

export const VALIDATION_REGEX = {
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
  WEBSITE:
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  CONTACT_NUMBER: /^\+?([0-9]){6,14}[0-9]$/,
};

export const URLS = {
  USER: "/user",
  GET_APIS: "/tokens",
  POST_APIS: "/token",
  GET_PRODUCTS: "/users/products",
  GET_REPORTS: "/reports",
  GET_METADATA: "/metadata/%s",
  UPDATE_FILE: "/file",
  GET_PRODUCT_BY_ID: "/product/%s",
  UPDATE_PRODUCT: "/link/product",
  GET_CONTRACTS: "/contract",
  CREATE_CONTRACT: "/contract",
  CONTRACT_BY_ID: "/contract/%s",
  SIGNUP: "/signup",
  GET_REPORTS_EAN: "/reports-ean",
  GET_REPORTS_NEW: "/reports-new",
  GET_LINKS: "/products/links/all",
  DELETE_LINK: '/product/link/remove',
  ADD_LINK: '/product/link/add'
};

export const ROLE_TYPE = {
  superadmin: 4,
  consumer: 5,
};

export const ROLE_NAME = "consumer";

export const FILE_TYPE = {
  image: 2,
  video: 1,
  driver: 3,
  document: 4,
};

export const PAGE_COUNT = 10;
export const MAX_FILE_SIZE = 10485760;

export const LANGUAGE_TYPE = [
  // {
  //   key: "ch",
  //   value: "Chinese",
  // },
  {
    key: "en",
    value: "English",
  },
  {
    key: "fr",
    value: "French",
  },

  // {
  //   key: "gr",
  //   value: "German",
  // },
  // {
  //   key: "hi",
  //   value: "Hindi",
  // },
  // {
  //   key: "ja",
  //   value: "Japanese",
  // },
  // {
  //   key: "po",
  //   value: "Portuguese",
  // },
  // {
  //   key: "ru",
  //   value: "Russian",
  // },
  // {
  //   key: "sp",
  //   value: "Spanish",
  // },
];

export const DEFAULT_LANGUAGE_TYPE = "en";
export const ALLOWED_ROLE = 5;
export const FRENCH_LANGUAGE = "fr";

export const COOKIE_CONFIG: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  position: "top-left",
  theme: "classic",
  palette: {
    popup: {
      background: "#000000",
      text: "#ffffff",
      link: "#ffffff",
    },
    button: {
      background: "#f1d600",
      text: "#000000",
      border: "transparent",
    },
  },
  type: "opt-out",
  content: {
    message:
      "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Got it!",
    deny: "Decline",
    allow: "Accept cookies",
    link: "",
    href: "",
    policy: "Cookie Policy",
  },
};
export const DEFAULT_LINE_CHART_CONFIG: ChartConfiguration["data"][] = [
  {
    datasets: [
      {
        data: [],
        label: "",
        fill: 1,
        backgroundColor: "rgba(65, 74, 92)",
        borderColor: "rgba(65, 74, 92)",
        borderWidth: 0,
        pointBackgroundColor: "rgba(65, 74, 92)",
        pointBorderColor: "rgba(65, 74, 92)",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(148,159,177,0.8)",
      },
      {
        data: [],
        label: "",
        fill: 1,
        backgroundColor: "#D80027",
        borderColor: "#D80027",
        borderWidth: 0,
        pointBackgroundColor: "#D80027",
        pointBorderColor: "#D80027",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(148,159,177,0.8)",
      },
    ],
    labels: [],
  },
];

export const DEFAULT_LINE_CHART_OPTION: ChartConfiguration["options"] = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0,
    },
  },
  scales: {
    y: {
      position: "left",
      suggestedMin: 0,
      ticks: {
        maxTicksLimit: 6,
        padding: 5,
      },
      grid: {
        tickLength: 2,
      },
    },
    x: {
      position: "bottom",
      ticks: {
        padding: 5,
      },
      grid: {
        tickLength: 1,
      },
    },
  },
};

<div class="cons-produ  mt-4 mb-5 d-inline-block w-100">
  <h2 class="font-weight-bold text-dark">{{'consumer' | translate }} </h2>
</div>
<h5 class="font-weight-bold pl-4 mb-4 text-dark">{{'consumer' | translate }}  {{'data' | translate }} </h5>

<div class="cons-part border border-primary text-dark rounded">
  <form class="form-horizontal d-inline-block w-100 p-4" role="form" [formGroup]="detailsForm">
    <div class="row">
      <div class="col-lg-9 order-2 order-lg-1">


        <div class="form-group row mt-0">
          <label for="first_name" class="col-lg-3 text-dark control-label ml-2 ml-md-0 mt-3 mt-md-3">{{'name' | translate }}</label>
          <div class="col-lg-9 ">
            <input  type="text" formControlName="first_name" placeholder="{{'enter' | translate }} {{'name' | translate }} "
              class="form-control border border-primary text-dark col-sm-9 d-inline-block text-dark" 
               id="first_name">
            <div class="text-danger pt-1" *ngIf="
            formControl.first_name.touched &&
            formControl.first_name.errors?.required">
              <small>{{'name' | translate }} {{'is required.' | translate }} </small>
            </div>
          </div>
        </div>

        <div class="form-group row mt-0">
          <label for="contact_number"  class="col-lg-3 text-dark control-label mt-0 mt-md-3">{{'phone number' | translate }}</label>
          <div class="col-lg-9 ">
            <input type="text" id="contact_number" placeholder="{{'enter' | translate }} {{'phone number' | translate }}"
            class="form-control border-primary text-dark col-sm-9 d-inline-block" formControlName="contact_number">
            <div class="text-danger pt-1" *ngIf="
            formControl.contact_number.touched &&
            formControl.contact_number.errors?.pattern">
              <small>{{'invalid' | translate }} {{'phone number' | translate }} </small>
            </div>
          </div>
        </div>
        <div class="form-group row mt-0">
          <label for="email" class="col-lg-3 text-dark control-label mt-0 mt-md-3"> {{'Mail address' | translate }}</label>
          <div class="col-lg-9 ">
            <input type="email" id="email"  placeholder="{{'enter' | translate }} {{'Mail address' | translate }}"
              class="form-control border border-primary text-dark col-sm-9 d-inline-block text-dark" formControlName="email" [readonly]="user" [ngClass]="{'disble-box':user}">

            <div class="text-danger pt-1" *ngIf="
            formControl.email.touched &&
            formControl.email.errors?.required">
              <small>Email {{'is required.' | translate }} </small>
            </div>
            <!-- <div class="text-danger pt-1">
              <small>Invalid Email. </small>
            </div> -->
          </div>
        </div>
        <div class="form-group row mt-0 mb-0">
          <label for="url" class="col-lg-3 text-dark control-label mt-0 mt-md-3">URL</label>
          <div class="col-lg-9 ">
            <input formControlName="url" type="text" id="url" placeholder="{{'enter' | translate }} url"
              class="form-control border border-primary text-dark col-sm-9 d-inline-block text-dark">
            <div class="text-danger pt-1" *ngIf="
            formControl.url.touched &&
            formControl.url.errors?.required">
              <small>URL {{'is required.' | translate }}  </small>
            </div>
            <div class="text-danger pt-1" *ngIf="
            formControl.url.touched &&
            formControl.url.errors?.pattern">
              <small>{{'invalid' | translate }}  URL. </small>
            </div>
          </div>
        </div>


      </div>
      <div class="col-lg-3 order-1 order-lg-2 pl-0">
        <div class="film-part ml-4 ml-lg-0 mt-0">

          <div class="film-box mt-2 mt-md-0 border border-primary text-dark rounded" (click)="addImage.click()">

            <div class="img-pt border border-primary text-dark position-relative d-inline-block rounded h-100" *ngIf="photoURL">

              <img [src]="photoURL"  class="img-wrapper">

            </div>

            <input #addImage hidden type="file" name="file[]" placeholder="{{'add Image' | translate }} " accept="image/*" id="addImage"
            (change)="selectFile($event, 'image')"  />
          </div>
          <button class="btn btn-primary w-140 mt-1" (click)="photoURL ? photoURL = null && photo = null : addImage.click()">{{ (photoURL ? 'remove Image' : 'add Image') | translate}}</button>

        </div>
      </div>

    </div>
    <div class="row mb-5">
      <div class="col-lg-12">
        <div class="form-group row mt-2">
          <label for="description" class="col-lg-2 text-dark mt-0 mt-md-3 pr-1 h-100 pt-1">{{'description' | translate }}</label>
          <div class="col-lg-9 mt-2 ml-0 ml-md-3 desc">
            <textarea id="description" class="form-control desc-heig border border-primary text-dark h-100 p-2" rows="4"
              placeholder="{{'enter' | translate }} {{'description' | translate }}" id="description" formControlName="description" maxlength="255"></textarea>
          </div>
        </div>
      </div>
    <!-- </div> -->
    <!-- <div class="row"> -->
      <div class="col-lg-9">
        <div class="form-group w-100 float-left mb-0 mt-1 mt-md-0">
          <div class="row pl-2">
            <label for="language_id" class="col-lg-3 mt-0 mt-md-3 pl-1 text-dark col-form-label">{{'language' | translate }}</label>
            <div class="col-8 col-lg-5 d-flex pl-1">
              <select id="language_id" class="form-control wt-50 border-primary text-dark" formControlName="language_id">
                <option *ngFor="let lan of languages" [value]="lan.id">{{lan.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group w-100 float-left mb-0 mt-3">
          <div class="row pl-2">
            <label class="col-6 col-md-0 col-lg-3 mt-0 mt-md-3 pl-1 text-dark col-form-label">{{'country' | translate }}</label>
            <div class="col-8 col-lg-5 pl-1">
              <div class="d-flex">
                <select id="country_id" class="form-control border-primary text-dark" formControlName="country_id">
                 <option *ngFor="let country of countries" [value]="country.id">{{country.name}} </option>
                </select>
              </div>
              <div class="text-danger pt-1" *ngIf="
                formControl.country_id.touched &&
                formControl.country_id.errors?.required
                  ">
                <small>{{'country' | translate }} {{'is required.' | translate }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
  <div class=" d-flex justify-content-end ">
    <div class="row form-group m-3">
    
      <button type="button"
        class="font-weight-bold font-size-18 rounded border border-primary  mt-1 btn text-dark pl-5 pb-3 pr-5 pt-3"
        (click)="doCancel()" >{{'cancel' | translate }}</button>
      <button type="button"
        class="btn button_form rounded font-size-18 font-weight-bold mt-1 text-white pl-5 pb-3 pr-5 pt-3"
        [disabled]="!detailsForm.valid" (click)="updateUser()">{{'save' | translate }}</button>
    </div>
  </div>

</div>
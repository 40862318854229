import { Auth } from "aws-amplify";
import { ERROR_MESSAGES } from "../../common/constants";

export const SignIn = async (email, password) => {
  let res = {
    result: null,
    message: null,
  };
  try {
    res.result = await Auth.signIn(email, password);
    console.log(await Auth.currentCredentials());
  } catch (err) {
    res.message = err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
  }
  return res;
};

export const ForgotPasswordSubmit = async (email, code, password) => {
  let res = {
    result: null,
    message: null,
  };
  try {
    console.log(await Auth.forgotPasswordSubmit(email, code, password));
    res.result = "SUCCESS"
  } catch (err) {
    res.message = err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
  }
  return res;
}

export const ForgotPassword = async (email) => {
  let res = {
    result: null,
    message: null,
  };
  try {
    res.result = await Auth.forgotPassword(email);
  } catch (err) {
    res.message = err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
  }
  return res;

};

export const completeNewPassword = async (user, password) => {
  let res = {
    result: null,
    message: null,
  };
  try {
    res.result = await Auth.completeNewPassword(user, password, {});
  } catch (err) {
    res.message = err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
  }
  return res;
};

export const SignOut = async () => {
  return await Auth.signOut();
};

export const isAuthenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (err) {
    return false;
  }
};



export const SignUp = async (data) => {
  let res = {
    result: null,
    message: null,
  };
  try {
    res.result = await Auth.signUp({
      username: data.email,
      password: data.password,
      attributes: {
        'given_name': data.first_name,
        'custom:role_name': data.role_name,
        'website': data.consumer_website,
        'phone_number': data.phone_number
      }
    });
    console.log(res.result);
  } catch (err) {
    res.message = err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
  }
  return res;
}


export const resetPasswords = async ( oldPassword, newPassword) => {
  let res = {
    result: null,
    message: null,
  };
  try {
    let user = await Auth.currentAuthenticatedUser()
    res.result = await Auth.changePassword(user, oldPassword, newPassword);
  } catch (err) {
    res.message = err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
  }
  return res;
}


export const ConfirmSignUp = async (username, code) => {
  let res = {
    result: null,
    message: null,
  };
  try {
    res.result = await Auth.confirmSignUp(username, code);
  } catch (err) {
    res.message = err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
  }
  return res;
}

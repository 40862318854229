import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { isAuthenticated } from "./auth.service";
import { ConsumerService } from "../consumer.service";
import { setInStorage } from '../../common/utils';
import { ROLE_TYPE } from '../../common/constants'; 

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {
  
  constructor(
    private router : Router,
    private consumerService: ConsumerService
  ){

  }
  canActivate = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    let { url } = state;
    const checkAuthentication = await isAuthenticated();
    if (checkAuthentication) {
        const result = await this.consumerService.loadUser();
        if (result && result.role && (result.role.id === ROLE_TYPE.consumer)) {
            setInStorage('consumer', result);
            this.router.navigate(["/consumer/dashboard"]);
            return false;
        }
    }
    return true;
  };
}

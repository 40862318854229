<div class="row">
  <div class="col-sm-5">
    <h2 class="api-design font-weight-bold text-dark position-static">API</h2>
  </div>
  <div class="col-sm-3">

  </div>
  <div class="col-sm-4">
    <button type="button" class="btn btn-primary rounded font-weight-bold font-style-normal btn-block api-btn" (click)="addApi = true">{{'Generate a new key'  | translate}}</button>
  </div>
</div>

<br>
<div *ngIf="!addApi" class="wrapper">
  <div *ngIf="apis && apis?.length > 0">
    <div class="table-responsive ">
      <table class="table table-bordered table-fixed mb-0">
        <thead class="text-primary">
          <tr>
            <th scope="col">{{'API Bearer token' | translate}}</th>
            <th scope="col">{{'Token Comment' | translate}}</th>
            <th scope="col">{{'Action' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr  *ngFor="let a of apis">
            <td>{{a?.token}}</td>
            <td>{{a?.comments}}</td>
            <td class=" cursor-pointer">
              <a class="edit-pen pl-3" (click)="editToken(a)"><img src="../../../assets/images/edit-icon1.png"></a>
              <a class="copy-pen pl-3" (click)="copyToken(a?.token)"><img src="../../../assets/images/copy-icon1.png"></a>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <app-pagination [totalItems]="totalPage" [pageSize]="pageCount" (pageClicked)="setPage($event)" [currentPage]="page"
      *ngIf="totalPage > pageCount">
    </app-pagination>
  </div>
  <!-- <div> TODO ADD NO API TOEKN HERE</div> -->
  <div *ngIf="!apis || apis?.length === 0"> {{'You do not have any tokens. Click on Generate a new key to create a token' | translate}}
  </div> 
</div>


<div *ngIf="addApi">
  <div class="descr-part">
<h2 class="pt-2 pl-2 pr-2 m-3 font-weight-bold font-size-18">{{'description' | translate}}</h2>

    <form [formGroup]="detailsForm" class="pt-0 pl-3 w-100 border border-light d-inline-block pb-5 rounded">
      <div class="form-group mb-0 mt-5 w-100 float-left font-size-17">
        <div class="row pl-3 ">
          <label class="col-lg-2 text-secondary col-form-label mt-0 mt-md-3">{{'comment' | translate}}</label>
          <div class="col-lg-8 mr-2">  
            <input formControlName="comments" id="comments" type="text" class="form-control border border-light rounded d-flex p-2 wt-50"
            placeholder="{{'enter' | translate}} {{'comment' | translate}}">       
          </div>
        </div>
  
      </div>
    </form>
  </div>

  <div class="mt-3 d-flex justify-content-end">                
    <button type="button" class="font-weight-bold rounded border border-primary btn  pl-5 pb-3 pr-5 pt-3"
(click)="addApi = false" >{{'cancel' | translate}}</button>
    <button type="button" class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3" [disabled]="!detailsForm.valid" (click)="addToken()" >{{'save' | translate}}</button>

</div>

</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-api-docs',
  templateUrl: './api-docs.component.html',
  styleUrls: ['./api-docs.component.scss']
})
export class ApiDocsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

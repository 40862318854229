import { FILE_TYPE, VALIDATION_REGEX, SUCCESS_MESSAGES } from './../../common/constants';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Product } from 'src/app/models/product';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { getFromStorage, convertMSToNgbDate, removeNullOrEmpty, removeNull } from 'src/app/common/utils';
import { User } from 'src/app/models/user';
import { ConsumerService } from 'src/app/services/consumer.service';
import { Country } from 'src/app/models/country';
import { Language } from 'src/app/models/language';
import { ERROR_MESSAGES } from 'src/app/common/constants';
import {TranslateService} from '@ngx-translate/core';
import { Links } from 'src/app/models/links';
import { Caracteristic } from 'src/app/models/Caracteristic';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: "app-edit-product",
  templateUrl: "./edit-product.component.html",
  styleUrls: ["./edit-product.component.scss"],
})
export class EditProductComponent implements OnInit {
  @Output() showProducts: EventEmitter<boolean> = new EventEmitter();
  @Input("product")
  product: Product;
  public detailsForm: FormGroup;
  public linkForm: FormGroup;
  public user: User;
  public countries: Country[];
  public languages: Language[];
  public isEdit: boolean = false;
  public FileType = FILE_TYPE;
  public caracteristics: Caracteristic[] = [];
  public files: File[] = [];
  public links: Links[] = [];
  public activeDocs: any;
  public activeEditLink: number = -1;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private consumerService: ConsumerService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.detailsForm = this.formBuilder.group({
      ean: [""],
      date: [null],
      enable: [true, Validators.required],
      title: [""],
      description: [""],
      country_name: [""],
      language_id: [null, Validators.required],
      manufacturer_reference: [null],
      asin: [""],
      manufacturer_name: [""],
    });

    this.linkForm = this.formBuilder.group({
      linkUrl: [
        "",
        [Validators.required, Validators.pattern(VALIDATION_REGEX.WEBSITE)],
      ],
      //linkUrl: ['']
    });

    this.loadUser();
    this.getLanguages();
    this.setActiveDocs();
    this.consumerService.switchLn.subscribe((ln: any) => {
      if (ln && ln.ln) this.translate.use(ln.ln);
    });

    this.getProductById();
  }

  getProductById = async () => {
    let productId = this.activatedRoute.snapshot.paramMap.get("id");
    if (productId) {
      this.spinner.show();
      try {
        this.product = await this.consumerService.getProductById(productId);
        this.spinner.hide();
        this.setProduct();
      } catch (err) {
        this.toastr.error(
          err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
        );
      } finally {
        this.spinner.hide();
      }
    }
  };

  loadUser = async () => {
    this.user = getFromStorage("consumer");
  };

  formControl(formName) {
    return this[formName].controls;
  }

  setProduct = () => {
    const valuesToPatch = [
      "ean",
      "date",
      "enable",
      "title",
      "description",
      "country",
      "language_id",
      "asin",
      "manufacturer_reference",
      "manufacturer",
    ];
    for (let val of valuesToPatch) {
      switch (val) {
        case "date":
          this.patchFormValue(val, convertMSToNgbDate(this.product.date));
          break;
        case "country":
          this.patchFormValue("country_name", this.product.country.name);
          break;
        case "language_id":
          if (this.product.language_id) {
            this.patchFormValue(val, this.product.language_id);
          }
          break;
        case "manufacturer":
          this.patchFormValue(
            "manufacturer_name",
            this.product.manufacturer.name
          );
          break;
        default:
          this.patchFormValue(val);
          break;
      }
    }

    const productFiles = ["links", "caracteristics", "files"];
    for (let file of productFiles) {
      if (this.product[file] && this.product[file].length > 0) {
        this[file] = this.product[file];
        for (let fl of this[file]) {
          if (fl.product_id) delete fl.product_id;
          if (fl.file_type) delete fl.file_type;
          if (
            file === "files" &&
            fl.type_id &&
            fl.type_id === FILE_TYPE.video
          ) {
            let sanUrl = this.getSanatizedUrl(fl.url);
            if (sanUrl) fl.external_url = sanUrl;
          }
        }
      }
    }
  };

  getSanatizedUrl(url) {
    if (url.search("youtu") > 0) return this.getYouTubeUrl(url);
    if (url.search("vimeo") > 0) return this.getVimeoUrl(url);
    return null;
  }

  getYouTubeUrl(ytUrl: string) {
    var regExp =
      /^.*((youtube\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = ytUrl.match(regExp);
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/".concat(match[7])
    );
  }

  getVimeoUrl(vUrl: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://player.vimeo.com/video/".concat(
        /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(vUrl)[3]
      )
    );
  }

  patchFormValue = (key, value = null) => {
    let patch = {};
    patch[key] = value ? value : this.product[key];
    this.detailsForm.patchValue(patch);
  };

  setActiveDocs = () => {
    this.activeDocs = {
      linkDetail: {
        link: null,
      },
    };
  };

  addLinks = () => {
    if(this.activeEditLink >= 0){
      this.links[this.activeEditLink].link = this.linkForm?.get("linkUrl")?.value;
    }else{
      let linkDetail = new Links();
      if (
        this.activeDocs &&
        this.activeDocs.linkDetail &&
        this.activeDocs.linkDetail.link
      )
      linkDetail.link = this.activeDocs.linkDetail.link;
      this.links.push(linkDetail);
    }
    this.resetActiveDoc("linkDetail");
    this.linkForm.reset();
  };

  resetActiveDoc = (type) => {
    for (let key of Object.keys(this.activeDocs[type])) {
      this.activeDocs[type][key] = null;
    }
  };

  getLanguages = async () => {
    this.spinner.show();
    try {
      this.languages = await this.consumerService.getMetaData("language");
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  doCancel = () => {
    this.router.navigate([`consumer/products/`]);
  };

  editProduct = async () => {
    let data: any = {};
    this.links.forEach((link) => removeNullOrEmpty(link));
    data.links = this.links;
    const url = `${this.product.country.code.toLocaleLowerCase()}/${this.product.language.code.toLocaleLowerCase()}/${this.product.ean}`;
    data = removeNullOrEmpty(data);
    this.spinner.show();
    try {
      let { success } = await this.consumerService.updateProduct({data:data,url:url});
      if (success) {
        this.toastr.success(SUCCESS_MESSAGES.PRODUCT_UPDATED);
        this.router.navigate([`consumer/products/`]);
      }
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };
  removeLink(i:number) {
    this.links.splice(i, 1);
  }
  editLink(i:number){
    this.activeEditLink = i;
    this.linkForm?.get("linkUrl")?.patchValue(this.links[i].link);
  }
}

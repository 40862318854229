import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ERROR_MESSAGES, MAX_FILE_SIZE, SUCCESS_MESSAGES, FILE_TYPE, VALIDATION_REGEX } from 'src/app/common/constants';
import { Country } from 'src/app/models/country';
import { Language } from 'src/app/models/language';
import { User } from 'src/app/models/user';
import { getS3Url, setInStorage, removeNull } from 'src/app/common/utils';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { ConsumerService } from './../../services/consumer.service';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-consumer',
  templateUrl: './consumer.component.html',
  styleUrls: ['./consumer.component.scss']
})
export class ConsumerComponent implements OnInit {
  public user: User;
  public FileType = FILE_TYPE;
  public photoURL;
  public detailsForm: FormGroup;
  public photo: File;
  public languages: Language[]
  public countries: Country[]
  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private consumerService: ConsumerService,
    private translate: TranslateService,
  ) { }
 

  ngOnInit(): void {
    this.detailsForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      email: ['', [Validators.required]],
      url: ['', [Validators.required, Validators.pattern(VALIDATION_REGEX.WEBSITE)]],
      country_id: [null, Validators.required],
      language_id: [null],
      contact_number: ['', Validators.pattern(VALIDATION_REGEX.CONTACT_NUMBER)],
      description: [''],
      photo_url: [''],
    })
    this.getCountries();
    this.getLanguages();
    this.consumerService.loggedInUser.subscribe((user: User) => {
      console.log("Inside subscription", user);
      if (user) {
        this.user = user;
        this.setFormValue();
      }
    });
  this.consumerService.switchLn.subscribe((ln: any) => {
    console.log('inside ln sub', ln);
    if(ln && ln.ln) this.translate.use(ln.ln);
  })
  }

  setFormValue = () => {
    let valuesToPatch = {};
    for (let key of Object.keys(this.user)) {
      valuesToPatch[key] = this.user[key];
    }
    this.detailsForm.patchValue(valuesToPatch);
    if (this.user.photo_url) this.photoURL = this.user.photo_url;
  }

  getCountries = async () => {
    this.spinner.show();
    try{
      this.countries = await this.consumerService.getMetaData('country');
    }catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }

  }

  getLanguages = async () => {
    this.spinner.show()
    try{
      this.languages = await this.consumerService.getMetaData('language');
    }catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  get formControl() {
    return this.detailsForm.controls;
  }

  selectFile = async (event, type) => {
    let { target: { files } } = event;
    let [file] = files;
    let [fileType] = file.type.split('/');

    if ((type === 'image') && fileType !== type) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message = 'Invalid File Format.';
      event.srcElement.value = null;
      return;
    }

    if (file && file.size > MAX_FILE_SIZE) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message = 'File too Big! Please select a file less than 10mb';
      event.srcElement.value = null;
      return;
    }

    if (file) {
      this.photo = file;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
      this.photoURL = reader.result;
      }
    }
  }

  updateUser = async () => {
    const allowedParams = ['first_name', 'contact_number', 'url', 'description', 'language_id', 'country_id', 'photo_url'];
    let data: any = {};
    
    for (let key of allowedParams) {
          //if (this.detailsForm.value[key]) {
            data[key] = this.detailsForm.value[key]
          //}
    }
    data = removeNull(data);
    if (this.photo && this.photoURL !== this.user.photo_url)  data['photo_url'] = await this.getFileUrl();
    if (this.user.photo_url && !this.photoURL) data['photo_url'] = '';
    data.id = this.user.id;
    this.spinner.show()
    try {
      await this.consumerService.putUser(data);
      for(let key of Object.keys(data)) {
        this.user[key] = data[key];
      }
      this.consumerService.loggedInUser.next(this.user);
      this.toastr.success(SUCCESS_MESSAGES.CONSUMER_UPDATED);
      setInStorage('consumer', this.user);
    } catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }

  }

  getFileUrl = async () => {
    let params = {
      file_name: this.photo.name,
      content_type: this.photo.type
    }
    this.spinner.show();
    try {
      // get signed url from backend
      let { key, url } = await this.consumerService.updateFile(params) || {};
      // upload file to signed url
      await this.consumerService.uploadFile(url, this.photo);
      this.spinner.hide();
      // return s3 url
      return getS3Url(key);
    } catch (err) {
      this.spinner.hide();
      let message = err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    }

    return null;
  }

  doCancel = () => {
    this.detailsForm.reset();
    this.setFormValue();
  }

}

// core modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from './app.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

// project modules
import { AppRoutingModule } from './app.routing.module';
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AuthLayoutModule } from './layouts/auth-layout/auth-layout.module';
import { ManagerLayoutComponent } from "./layouts/manager-layout/manager-layout.component";
import { ManagerLayoutModule } from './layouts/manager-layout/manager-layout.module';
import { AuthGuard } from "./services/auth/auth.guard";
import { ComponentsModule } from './components/component.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgcCookieConsentModule } from "ngx-cookieconsent";
import { COOKIE_CONFIG } from "./common/constants";
@NgModule({
  imports: [
    BrowserModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule.forRoot({ include: ["/"] }),
    CommonModule,
    RouterModule,
    AppRoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AuthLayoutModule,
    ManagerLayoutModule,
    ToastrModule.forRoot(),
    ComponentsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgcCookieConsentModule.forRoot(COOKIE_CONFIG),
  ],
  declarations: [AppComponent, AuthLayoutComponent, ManagerLayoutComponent],
  bootstrap: [AppComponent],
  providers: [AuthGuard],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// core modules
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {TranslateService} from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";
const util = require('util');
// project modules
import { SignOut } from '../../services/auth/auth.service';
import { clearStorage, getFromStorage, setInStorage } from '../../common/utils';
import { ConsumerService } from '../../services/consumer.service';
import { Consumer } from 'src/app/models/consumer';
import { LANGUAGE_TYPE, DEFAULT_LANGUAGE_TYPE, SUCCESS_MESSAGES, ERROR_MESSAGES } from 'src/app/common/constants';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  host: {
    '(document:click)': 'onClick()',
  }
})

export class HeaderComponent implements OnInit {
  @Output() toggleSideNav: EventEmitter<boolean> = new EventEmitter();
  public showLanguageDropDown: boolean = false;
  public showDropDown: boolean = false;
  public user: Consumer;
  public Language_type = LANGUAGE_TYPE;
  public selectedIndex: string;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private consumerService: ConsumerService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.consumerService.switchLn.subscribe((ln: any) => {
      console.log('inside ln sub', ln);
      if(ln && ln.ln) this.translate.use(ln.ln);
    })
    this.loadUser();
    this.consumerService.loggedInUser.next(this.user);
  }

  clickMenu = () => {
    this.toggleSideNav.emit(true);
  }

  logOut = async () => {
    this.spinner.show()
    await SignOut();
    clearStorage();
    this.spinner.hide();
    this.router.navigate(["/login"]);
  }

  loadUser = async() => {
    if(getFromStorage('consumer')) {
      this.user = getFromStorage('consumer');
    }
    else{
    this.user = await this.consumerService.getUser();
    setInStorage('consumer', this.user);
    }
    if(this.user.language && this.user.language!= null ){
      this.consumerService.switchLn.next({ln: this.user.language});
      this.selectedIndex = this.user.language;
    }
    else {
      this.consumerService.switchLn.next({ln: DEFAULT_LANGUAGE_TYPE});
      this.selectedIndex = DEFAULT_LANGUAGE_TYPE;
    }
  }

  setLanguage = async (ln ) => 
  { this.selectedIndex = ln;
    this.spinner.show();
    try {
        let data = {
          id: this.user.id,
          language: ln
        }
        await this.consumerService.putUser(data);
        this.user.language=data.language;
        setInStorage('consumer', this.user);
        this.consumerService.switchLn.next({ln: ln});
        this.toastr.success(util.format(SUCCESS_MESSAGES.LANGUAGE_SWITCHED, LANGUAGE_TYPE.filter(x => x.key===ln)[0].value));         
    }catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.LANGUAGE_SWITCH);
    } finally {
      this.spinner.hide();
    }
  }

  openUser(e){
    e.stopPropagation() 
    return(this.showDropDown = !this.showDropDown);
  }

  openLanguage(e){
    e.stopPropagation()
    return(this.showLanguageDropDown = !this.showLanguageDropDown);
  }

  onClick(){
    if(this.showDropDown && !this.showLanguageDropDown) this.showDropDown = false;
    if(this.showLanguageDropDown) this.showLanguageDropDown = false;
  }  


}

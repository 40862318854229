import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-link-confirmation',
  templateUrl: './delete-link-confirmation.component.html',
  styleUrls: ['./delete-link-confirmation.component.scss']
})
export class DeleteLinkConfirmationComponent {
  @Input() public message;
  @Input() public showCancel = true;;

  constructor(public activeModal: NgbActiveModal) { }
}

import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Product } from 'src/app/models/product';
import { NgxSpinnerService } from "ngx-spinner";
import { ConsumerService } from 'src/app/services/consumer.service';
import { TableSortableHeader, SortEvent } from 'src/app/directives/tablesortableheader.directives';
import { PAGE_COUNT, ERROR_MESSAGES } from 'src/app/common/constants';
import {TranslateService} from '@ngx-translate/core';
const compare = (v1: any, v2: any) => {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  
  public products: Product[];
  public product: Product;
  public page: number = 1;
  public pageCount: number = PAGE_COUNT;
  public totalPage: number = 0;
  public editProduct: boolean = false;
  @ViewChildren(TableSortableHeader) headers: QueryList<TableSortableHeader>;
  constructor(
    private spinner: NgxSpinnerService,
    private consumerService:ConsumerService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    if (this.activatedRoute.snapshot.paramMap.get('id')) {
      this.getProduct(this.activatedRoute.snapshot.paramMap.get('id'));
    } else {
      this.loadProducts(1);
    }

    this.consumerService.switchLn.subscribe((ln: any) => {
      console.log('inside ln sub', ln);
      if(ln && ln.ln) this.translate.use(ln.ln);
    })

  }

  loadProducts = async (page = null) => {
    this.page = page;
    this.spinner.show();
    try{
    let { results, totalCount } = await this.consumerService.getProducts(this.page, PAGE_COUNT);
    this.totalPage = totalCount;
    this.products = results;
    }catch (err) {
      let message = err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    }finally {
      this.spinner.hide();
    }
  }

  public onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    // sorting countries
    if (direction === "" || column === "") {
    } else {
      this.products = [...this.products].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === "asc" ? res : -res;
      });
    }
  }

  setPage = (page) => {
    this.loadProducts(page);
  }

  goToProduct = (event, id) => {
    event.stopPropagation();
    this.router.navigate([`consumer/products/${id}`]);
  }

  getProduct = async (id) => {
    this.spinner.show();
    try{
    this.product = await this.consumerService.getProductById(id);
    this.spinner.hide();
    this.editProduct = true;
    }catch (err) {
      this.spinner.hide();
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } 
  }

  showList = (event) => {
    this.editProduct = false;
    //this.searchText = '';
    this.page = 0;
    this.loadProducts();
    this.router.navigate([`manager/products`]);
  }


}

import { Routes } from "@angular/router";
import { LoginComponent } from "../../pages/login/login.component";
import { ForgotComponent } from 'src/app/pages/forgot/forgot.component';
import { SignupProgressComponent } from 'src/app/pages/signup-progress/signup-progress.component';
import { SignUpComponent } from 'src/app/pages/sign-up/sign-up.component';

export const AuthLayoutRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "forgot", component: ForgotComponent },
  { path: "signup", component: SignUpComponent },
  { path: "account-activation", component: SignupProgressComponent },
  { path: "", redirectTo:"login", pathMatch:"full" },
];

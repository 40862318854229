import { ROLE_TYPE } from './../../common/constants';
// core modules
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';

import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  VALIDATION_REGEX,
  ALLOWED_ROLE,
  DEFAULT_LANGUAGE_TYPE,
  FRENCH_LANGUAGE,
} from "../../common/constants";
import { ConfirmedValidator } from "../../common/customValidators/passwordMatch";
// project modules
import { completeNewPassword, SignIn } from "../../services/auth/auth.service";
import { ConsumerService } from 'src/app/services/consumer.service';
import { setInStorage } from 'src/app/common/utils';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  isMutePass: boolean = false;
  langSelected = DEFAULT_LANGUAGE_TYPE;

  public loginForm: FormGroup;
  public resetPasswordForm: FormGroup;
  public action: string = "login";
  protected user: any;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private consumerService: ConsumerService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });

    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ["", [Validators.required, Validators.pattern(VALIDATION_REGEX.PASSWORD)],],
        cnfrmPassword: ["", [Validators.required]],
      },
      {
        validator: ConfirmedValidator("password", "cnfrmPassword"),
      }
    );
  }

  get formControl() {
    return this[this.getActiveForm()].controls;
  }

  getActiveForm = () => {
    switch (this.action) {
      case "login":
        return "loginForm";
      case "resetPassword":
        return "resetPasswordForm";
    }
  };

  onSubmit = () => {
    if (this.action === "login") {
      this.doLogin();
    } else {
      this.resetPassword();
    }
  };

  doLogin = async () => {
    let { email, password } = this.loginForm.value;
    this.spinner.show();
    let { message, result } = await SignIn(email.toLowerCase(), password);
    this.spinner.hide();
    if (!result) {
      this.toastr.error(message);
      return;
    }
    const { challengeName } = result;
    if (challengeName === "NEW_PASSWORD_REQUIRED") {
      this.goToReset(result);
      return;
    }
    this.getConsumer();
  };

  getConsumer = async () => {
    this.spinner.show();
    try {
      let result = await this.consumerService.getUser();
      if (result && result.role && (result.role.id === ROLE_TYPE.consumer)) {
        setInStorage('consumer', result);
        this.toastr.success(SUCCESS_MESSAGES.LOGIN_SUCCESSFUL);
        this.router.navigate(["/consumer/dashboard"]);
        return;
      }
      this.toastr.error(result.role.id === ROLE_TYPE.consumer ? 'This account is disabled.' : 'Not Authorized.')
    } catch (err) {
      this.toastr.error(err.message);
    }
    this.spinner.hide();
  }


  resetPassword = async () => {
    const { result, message } = await completeNewPassword(
      this.user,
      this.resetPasswordForm.value.password
    );
    if (!result) {
      this.toastr.error(message);
    }
    this.toastr.success(SUCCESS_MESSAGES.RESET_PASSWORD);
    this.getConsumer();
  };

  goToReset = (result) => {
    this.action = "resetPassword";
    this.user = result;
    this.toastr.error(ERROR_MESSAGES.CHANGE_PASSWORD);
  };

  languageSelected(ln: string) {
    if (ln === DEFAULT_LANGUAGE_TYPE)
      this.langSelected = DEFAULT_LANGUAGE_TYPE;
    if (ln == FRENCH_LANGUAGE)
      this.langSelected = FRENCH_LANGUAGE
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { ChartConfiguration } from "chart.js";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConsumerService } from "src/app/services/consumer.service";
import {
  DEFAULT_LINE_CHART_CONFIG,
  DEFAULT_LINE_CHART_OPTION,
  ERROR_MESSAGES,
} from "src/app/common/constants";
import { TranslateService } from "@ngx-translate/core";
import { MonthsPipe } from "src/app/common/customPipes/months.pipe";
import { Subscription } from "rxjs";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public reports: any[];
  public lineChartsConfig: ChartConfiguration["data"][] =
    DEFAULT_LINE_CHART_CONFIG;

  public lineChartOptions: ChartConfiguration["options"] =
    DEFAULT_LINE_CHART_OPTION;
  titles: string[] = ["Number of EAN called by API"];
  public lineChartLabels = [];
  public lineChartLegend = true;
  public lineChartType = "line";
  switchLnSubscription!: Subscription;
  // * ------- line charts config ------------------- *//

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private consumerService: ConsumerService,
    private translate: TranslateService,
    private monthPipe: MonthsPipe
  ) {}

  ngOnInit(): void {
    this.init();
  }
  init() {
    this.getReports();
    this.switchLnSubscription = this.consumerService.switchLn.subscribe(
      (ln: any) => {
        if (ln && ln.ln) this.translate.use(ln.ln);
      }
    );
  }
  getReports = async () => {
    this.spinner.show();
    try {
      let { results } = await this.consumerService.getNewReport();
      this.reports = results;
      this.setLineCharts();
    } catch (err) {
      this.toastr.error(
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };
  setLineCharts = (id = null) => {
    const lineGraphPointsData = this.getGraphPointObject();
    this.getGraphPointObject();
    this.lineChartLabels = Object.keys(lineGraphPointsData).sort((a, b) =>
      a > b ? 1 : -1
    );
    const chartDataForManualProducts = [];
    const chartDataForScrappedProducts = [];
    this.lineChartLabels.forEach((label) => {
      chartDataForManualProducts.push(
        lineGraphPointsData?.[label.toString()].manualProducts
      );
      chartDataForScrappedProducts.push(
        lineGraphPointsData?.[label.toString()].scrappedProducts
      );
    });
    this.lineChartsConfig[0].datasets[0] = {
      ...this.lineChartsConfig[0].datasets[0],
      data: chartDataForManualProducts,
      label: "Manually Added Products",
    };
    this.lineChartsConfig[0].datasets[1] = {
      ...this.lineChartsConfig[0].datasets[1],
      data: chartDataForScrappedProducts,
      label: "Scraped Products",
    };
    this.lineChartsConfig[0].labels = this.lineChartLabels.map((date) => {
      const graphDate = new Date(date.toString());
      return `${this.monthPipe
        .transform(graphDate)
        .substring(0, 3)}-${graphDate.getFullYear()}`;
    });
  };

  getGraphPointObject() {
    const graphPointObject = {};
    this.reports?.["graph"]?.forEach((element) => {
      let manualProduct = 0;
      let scrappedProduct = 0;
      if (element?.source === "web") {
        manualProduct = element.total;
      } else {
        scrappedProduct = element.total;
      }
      if (graphPointObject.hasOwnProperty(element?.formatted_date)) {
        graphPointObject[element?.formatted_date].manualProducts =
          graphPointObject[element?.formatted_date].manualProducts +
          manualProduct;
        graphPointObject[element?.formatted_date].scrappedProducts =
          graphPointObject[element?.formatted_date].scrappedProducts +
          scrappedProduct;
      } else {
        graphPointObject[element?.formatted_date] = {
          manualProducts: manualProduct,
          scrappedProducts: scrappedProduct,
        };
      }
    });
    return graphPointObject;
  }

  setLineChartData = (index: number, lines: any) => {
    Object.keys(lines).forEach((line, i) => {
      this.lineChartsConfig[index].datasets[i].data = this.getLineDataArray(
        lines[line].id
      );
      this.lineChartsConfig[index].datasets[i].label = lines[line].title;
    });
  };

  getLineDataArray = (id) => {
    let result = [];
    for (let key of Object.keys(this.reports)) {
      result.push(this.reports[key][id]);
    }
    return result.reverse();
  };

  setLineChartLabels = () => {
    this.lineChartLabels = Object.keys(this.reports).reverse();
  };

  ngOnDestroy(): void {
    this.switchLnSubscription?.unsubscribe();
  }
}

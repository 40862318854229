import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

import { API } from 'src/app/models/api';
import { ConsumerService } from 'src/app/services/consumer.service';
import { SUCCESS_MESSAGES, PAGE_COUNT } from 'src/app/common/constants';
import { removeNull } from 'src/app/common/utils';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss']
})
export class ApiComponent implements OnInit {
  public apis: API[];
  private isEdit: boolean = false;
  private api: API;
  public detailsForm: FormGroup;
  public page: number = 1;
  public pageCount: number = PAGE_COUNT;
  public totalPage: number = 0;

  public addApi: boolean = false;
  constructor(private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private consumerService: ConsumerService,
    private translate: TranslateService,
    ) { }

  ngOnInit(): void {
    this.getApiList(1);
    this.consumerService.switchLn.subscribe((ln: any) => {
      console.log('inside ln sub', ln);
      if(ln && ln.ln) this.translate.use(ln.ln);
    })
    this.detailsForm = this.formBuilder.group({
      comments: [""],
    });
  }

  getApiList = async ( page ) => {
    this.page = page;
    this.spinner.show();
    try {
      let { results, totalCount }= await this.consumerService.getApi(this.page, this.pageCount);
      this.apis = results;
      this.totalPage = totalCount;
    } catch (err) {
      this.toastr.error(err.message);
    } finally{
      this.spinner.hide();
    }
  }

  setPage = (page) => {
    this.getApiList(page);
  }

  addToken = async () => {
    let data = this.detailsForm.value;
    data = removeNull(data);
    if (this.isEdit) data.id = this.api.id;
    this.spinner.show();
    try {
      let { success } = await this.consumerService.AddApi(data);
      if (success) {
        let message = this.isEdit ? SUCCESS_MESSAGES.API_UPDATED : SUCCESS_MESSAGES.API_ADDED;
        this.toastr.success(message);
        this.addApi = false;
        this.detailsForm.reset();
        this.getApiList(1);
      }
    } catch (err) {
      this.toastr.error(err.message);
    } finally{
    this.spinner.hide();
    }
  }

  editToken = (token) => {
    this.api = token;
    this.addApi = true;
    this.isEdit = true;
    const fieldsToPatch = ['comments'];
    for (let field of fieldsToPatch) {
      let patch = {};
      patch[field] = token[field];
      this.detailsForm.patchValue(patch);
    }
  }

  copyToken = (token) => {
    const textArea = document.createElement("textarea");
    try {
      textArea.value = token;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      this.toastr.info(SUCCESS_MESSAGES.TOKEN_COPIED);
    } catch (err) {
      this.toastr.error(SUCCESS_MESSAGES.TOKEN_COPIED_ERROR);
    } finally {
      document.body.removeChild(textArea);
    }

  }

}

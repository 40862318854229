import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  ERROR_MESSAGES,
  PAGE_COUNT,
  VALIDATION_REGEX,
} from "src/app/common/constants";
import { Product } from "src/app/models/product";
import { ConsumerService } from "src/app/services/consumer.service";

@Component({
  selector: "app-create-link",
  templateUrl: "./create-link.component.html",
  styleUrls: ["./create-link.component.scss"],
})
export class CreateLinkComponent implements OnInit {
  public products: Product[];
  public addLinkForm: FormGroup;
  public isSubmitted: boolean = false;
  constructor(
    private spinner: NgxSpinnerService,
    private consumerService: ConsumerService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadProducts();
    this.consumerService.switchLn.subscribe((ln: any) => {
      console.log("inside ln sub", ln);
      if (ln && ln.ln) this.translate.use(ln.ln);
    });
  }

  initForm() {
    this.addLinkForm = this.fb.group({
      link: [
        "",
        [Validators.required, Validators.pattern(VALIDATION_REGEX.WEBSITE)],
      ],
      product_url: [null, [Validators.required]],
    });
  }

  loadProducts = async (page = null) => {
    this.spinner.show();
    try {
      let { results, totalCount } = await this.consumerService.getProducts(
        page,
        10000
      );
      this.products = results;
    } catch (err) {
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    } finally {
      this.spinner.hide();
    }
  };

  addLink() {
    if (this.addLinkForm.invalid) {
      this.addLinkForm.markAllAsTouched();
      this.addLinkForm.markAsDirty();
      this.toastr.error("Please fill required fields");
      return;
    }
    const payload = {
      ...this.addLinkForm.value,
    };
    this.isSubmitted = true;
    try {
      this.consumerService.addLink(payload).then((res) => {
        console.log(res);
        this.activeModal.close(true);
        this.isSubmitted = false;
      });
    } catch (err) {
      console.error(err);
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
      this.isSubmitted = false;
    }
  }
}

<div class="main-produ mt-5 mb-3 d-inline-block w-100 flex-wrap d-flex justify-content-between">
  <h2 class=" font-weight-bold text-dark">{{'Links' | translate }} </h2>
  <div class=" d-flex align-items-center">
    <input class="form-control search-top" placeholder="Search by EAN" [(ngModel)]="searchText">
    <button type="button"
      class="btn ml-2 search-btn btn-primary rounded font-weight-bold font-style-normal btn-block api-btn"
      (click)="openCreateLinkDialog()">{{'Add Link'  | translate}}</button>
  </div>
</div>
<br>
<div class="wrapper">
  <div *ngIf="links && links?.length > 0">
    <div class="table-responsive ">
      <div class="force-overflow"></div>
      <table class="table table-bordered table-fixed mb-0">
        <thead class="text-primary">
          <tr>
            <th scope="col" sortable="ean" (sort)="onSort($event)">EAN</th>
            <th scope="col" sortable="title" (sort)="onSort($event)">{{'title' | translate }}</th>
            <th scope="col">{{'Language' | translate }}</th>
            <th class="col4" scope="col">{{'country' | translate }}</th>
            <th class="col4" scope="col">{{'Link' | translate }}</th>
            <th class="col4" scope="col">{{'Tools' | translate }}</th>
            <!-- <th scope="col">Enabled</th>     -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let link of links | tableFilter: searchText: ['ean'];let i = index">
            <td class="cursor-pointer"> {{link?.ean}} </td>
            <td class="cursor-pointer"> {{link?.title}} </td>
            <td class="cursor-pointer"> {{link?.lang_code}} </td>
            <td class="cursor-pointer"> {{link?.country_code}} </td>
            <td class="cursor-pointer">{{link?.link}} </td>
            <td class="cursor-pointer">
              <a (click)="openDeleteLinkconfirmation(link.id)" class="cursor-pointer"><img
                  src="../../../assets/images/delete-icon.png"></a>
            </td>
          </tr>
      </table>
    </div>

    <app-pagination [totalItems]="totalPage" [pageSize]="pageCount" (pageClicked)="setPage($event)" [currentPage]="page"
      *ngIf="totalPage > pageCount">
    </app-pagination>
  </div>
  <div *ngIf="!links || links?.length === 0"> {{'no' | translate }} {{'links' | translate }}</div>
</div>

// core modules
import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgChartsModule } from "ng2-charts";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { TableSortableHeader } from "src/app/directives/tablesortableheader.directives";
import { ContractsComponent } from "src/app/pages/contracts/contracts.component";
import { DashboardComponent } from "src/app/pages/dashboard/dashboard.component";
import { PaginationComponent } from "src/app/pages/pagination/pagination.component";
import { ViewContractComponent } from "src/app/pages/view-contract/view-contract.component";
import { ApiComponent } from "../../pages/api/api.component";
import { ProductsComponent } from "../../pages/products/products.component";
// project modules
import { FilterPipe } from "./../../common/customPipes/filter.pipe";
import { ApiDocsComponent } from "./../../pages/api-docs/api-docs.component";
import { ConsumerComponent } from "./../../pages/consumer/consumer.component";
import { EditProductComponent } from "./../../pages/edit-product/edit-product.component";
import { ManagerLayoutRoutes } from "./manager-layout.routing";
import { ChangePasswordComponent } from "../../pages/change-password/change-password.component";
import { SharedModule } from "src/app/common/shared/shared.module";
import { LinksComponent } from "src/app/pages/links/links.component";
import { DeleteLinkConfirmationComponent } from "src/app/pages/links/components/delete-link-confirmation/delete-link-confirmation.component";
import { CreateLinkComponent } from "src/app/pages/links/components/create-link/create-link.component";
import { TableFilterPipe } from "src/app/common/customPipes/table-filter.pipe";

const PIPES = [TableFilterPipe];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ManagerLayoutRoutes),
    NgChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PdfViewerModule,
    SharedModule,
  ],
  declarations: [
    ...PIPES,
    DashboardComponent,
    ApiComponent,
    ProductsComponent,
    TableSortableHeader,
    PaginationComponent,
    ApiDocsComponent,
    ConsumerComponent,
    EditProductComponent,
    FilterPipe,
    ContractsComponent,
    ViewContractComponent,
    ChangePasswordComponent,
    LinksComponent,
    DeleteLinkConfirmationComponent,
    CreateLinkComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ManagerLayoutModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
``;

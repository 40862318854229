<!-- Start Page content holder -->
<div class="" id="content">
  <div class="Main-hesd">
    <h2 class="font-weight-bold text-dark">{{ 'dashboard' | translate }}</h2>
  </div>

  <div class="chart-cri">
    
    <div class="row" *ngFor="let chart of lineChartsConfig;let i=index">
      <div class="col-md-12">
        <div class="chart-box">
          <h6 translate>{{titles[i]}}</h6>
          <div class="chart_div">
            <canvas baseChart id="myChart_demo1" [datasets]="chart?.datasets" [labels]="chart.labels"
              [options]="lineChartOptions"
              [type]="lineChartType">
            </canvas>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
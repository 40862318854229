<div class="vertical-nav navbar-vertical" id="sidebar" [ngClass]="{'active':showSideNav}">
  <div class="px-3 mb-2 mt-2">
    <div class="align-items-center" id="half">
      <a class="navbar-brand text-center" href="#"><img src="../../../assets/images/logo.svg"></a>

      <!-- <button id="sidebarCollapse1" type="button" class="float-right" (click)="showSideNav = false"><i
          class="fa fa-close"></i></button> -->
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showSideNav = false"
        id="sidebarCollapse1">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>

    </div>
  </div>
  <ul class="nav flex-column mb-0">
    <li *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
      <a routerLinkActive="active" [routerLink]="['/consumer',menuItem.path]" class="nav-link"
        (click)="showSideNav = false" translate>
        <img *ngIf="!menuItem.fontIcon" [src]="['../../../assets/images/'+menuItem.icon]">
        <i *ngIf="menuItem.fontIcon" [class]="menuItem.fontIcon"></i>
        <span translate>{{menuItem.title}}</span> 
      </a>
    </li>

  </ul>
</div>
<div style="text-align:center" class="absolute">Beta 0.0.48</div>
<!-- <div style="text-align:center" class="absolute"> Beta {{ ver }} </div> -->
import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ERROR_MESSAGES, PAGE_COUNT } from "src/app/common/constants";
import {
  SortEvent,
  TableSortableHeader,
} from "src/app/directives/tablesortableheader.directives";
import { Contract } from "src/app/models/contract";
import { getAll, getContractDetails } from "src/app/services/contract.service";

const compare = (v1: any, v2: any) => {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

@Component({
  selector: "app-contracts",
  templateUrl: "./contracts.component.html",
  styleUrls: ["./contracts.component.scss"],
})
export class ContractsComponent implements OnInit {
  public contracts: Contract[] = [];
  public page: number = 1;
  public pageCount: number = PAGE_COUNT;
  public totalPage: number = 0;

  @ViewChildren(TableSortableHeader) headers: QueryList<TableSortableHeader>;
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  public onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    if (direction === "" || column === "") {
    } else {
      this.contracts = [...this.contracts].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === "asc" ? res : -res;
      });
    }
  }

  setPage = (page) => {
    this.loadContracts(page);
  };

  async loadContracts(page) {
    this.page = page;
    this.spinner.show();
    try {
      let { results, totalCount } = await getAll(this.page, this.pageCount);
      this.totalPage = totalCount;
      this.contracts = results;
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  }

  ngOnInit(): void {
    this.loadContracts(1);
  }

  // This function is called to download pdf version
  async download(contract: Contract) {
    // Create pdf key
    let pdfKey = contract.contract_description.replace(".html", ".pdf");
    let link = await getContractDetails(pdfKey, false);

    let downloadLink = document.createElement("a");
    downloadLink.href = link;
    downloadLink.target = "_blank";
    // now set the visibility to hidden so that it doesnt effect the frontend layout
    downloadLink.download = "file_name";
    // now append it to the document, generate click and remove the link
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}

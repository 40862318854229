<nav>
    <ul class="pagination flex-wrap justify-content-end mb-0">
        <li class="page-item" [ngClass]="{ 'disabled':currentPage===1}">
            <a class="page-link" (click)="setPage(1)">
                <i class="fas fa-angle-double-left"></i>
                <span class="sr-only">First</span>
            </a>
        </li>
        <li class="page-item" [ngClass]="{ 'disabled':currentPage===1}">
            <a class="page-link" (click)="setPage(currentPage-1)">
                <i class="fas fa-angle-left"></i>
                <span class="sr-only">Previous</span>
            </a>
        </li>
        <li class="page-item" *ngFor="let page of pages" [ngClass]="{active:currentPage === page}">
            <a class="page-link" (click)="setPage(page)">{{page}}</a>
        </li>
        <li class="page-item" [ngClass]="{ 'disabled': currentPage == totalPages}">
            <a class="page-link" (click)="setPage(currentPage+1)">
                <i class="fas fa-angle-right"></i>
                <span class="sr-only">Next</span>
            </a>
        </li>
        <li class="page-item" [ngClass]="{ 'disabled': currentPage == totalPages}">
            <a class="page-link" (click)="setPage(totalPages)">
                <i class="fas fa-angle-double-right"></i>
                <span class="sr-only">Last</span>
            </a>
        </li>
    </ul>
</nav>
<div class="row page_wrapper ml-0">
  <div class="col-md-6 text-center pr-0 pl-0 d-none d-md-inline-block">
    <div class="w-100 h-100">
      <img src="../../../assets/images/signup-img.png" class="d-md-block d-none wh-inherit">
    </div>
    <div class="d-flex justify-content-center">
      <div class="position-absolute d-md-block d-none bg-blur ">
        <img src="../../../assets/images/logo1.png" class="mt-5">
        <div class="border-line border-top position-absolute">
          <img src="../../../assets/images/Plug.svg" class="mt-4">
        </div>
      </div>
    </div>
    <div class="position-relative bottom-55 ">
      <ngb-carousel>
        <ng-template ngbSlide>
          <div class="carousel-caption">
            <p *ngIf="langSelected=='en'" class="text-justify font-size-14 font-weight-bold">Describy, the customer
              centric marketing
              revolution —
              Discover an innovative
              solution to address your consumers and prospects. A few clicks are enough to create an efficient promotion
              campaign in line with your marketing campaign : online or offline, localized or national, to reach a
              targeted or a wide audience.</p>
            <p *ngIf="langSelected=='fr'" class="text-justify font-size-14 font-weight-bold"> Décrivez, la révolution du
              marketing centré sur le client — Découvrez une solution innovante pour vous adresser à vos consommateurs
              et prospects. Quelques clics suffisent pour créer une campagne de promotion efficace en phase avec votre
              campagne marketing : en ligne ou hors ligne, localisée ou nationale, pour toucher un public ciblé ou
              large.</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="carousel-caption">
            <p class="text-justify">Describy, 2.</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="carousel-caption">
            <p class="text-justify">Describy, 3.</p>
          </div>
        </ng-template>
      </ngb-carousel>
      <ul class="lang-switcher position-absolute m-0 p-0  text-change font-size-14">
        <li class=" d-inline-block p-2  font-size-14 " [class.active]="langSelected=='fr'"><a
            class="text-white  font-size-14 font-weight-400 d-block cursor-pointer bg-wh"
            (click)="languageSelected('fr')">FR</a>
        </li>
        <li class="vertical-row position-absolute d-inline-block"></li>
        <li class="d-inline-block rounded-circle p-2  font-size-14 " [class.active]="langSelected=='en'"><a
            class="text-white font-size-14 font-weight-400 d-block cursor-pointer bg-wh"
            (click)="languageSelected('en')">EN</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-6 pl-0 pr-0 my-auto">
    <div class="text-center mx-auto mt-md-4 mt-0 col-12 col-sm-10 col-md-12 col-lg-10">
      <img src="https://describy.com/assets/images/logo.svg">
      <h4 *ngIf="langSelected=='en'" class="font-weight-bold mt-5 pt-md-5 pt-0">ACCOUNT CREATION IN PROGRESS...</h4>
      <h4 *ngIf="langSelected=='fr'" class="font-weight-bold mt-5 pt-md-5 pt-0">CRÉATION DE COMPTE EN COURS...</h4>
      <P *ngIf="langSelected=='en'" class="font-weight-bold font-size-12 mt-4 text-light-brown">Our team is working on
        validating your account
        creation.<br>Soon
        you will receive an email with connection instructions. </P>

      <P *ngIf="langSelected=='fr'" class="font-weight-bold font-size-12 mt-4 text-light-brown">Notre équipe travaille à
        la validation de la création de votre compte.
        <br>Bientôt, vous recevrez un e-mail avec les instructions de connexion.</P>
      <a *ngIf="langSelected=='en'" class="font-weight-bold font-size-12 text-orange text-decoration" href="#">Check
        inbox</a>
      <a *ngIf="langSelected=='fr'" class="font-weight-bold font-size-12 text-orange text-decoration" href="#">Vérifier
        la boîte de réception</a>

    </div>
  </div>
</div>
// core modules 
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// project modules
import { LoginComponent } from "../../pages/login/login.component";
import { AuthLayoutRoutes } from "./auth-layout.routing";
import { ForgotComponent } from 'src/app/pages/forgot/forgot.component';
import { SignUpComponent } from 'src/app/pages/sign-up/sign-up.component';
import { SignupProgressComponent } from 'src/app/pages/signup-progress/signup-progress.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AuthLayoutRoutes),
    FormsModule,
    NgbModule,
    ReactiveFormsModule
  ],
  declarations: [LoginComponent, ForgotComponent, SignUpComponent, SignupProgressComponent],
})
export class AuthLayoutModule { }

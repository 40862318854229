import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ConsumerService } from "../consumer.service";
import { isAuthenticated } from "./auth.service";
import { setInStorage } from '../../common/utils';
import { ROLE_TYPE } from '../../common/constants'; 

@Injectable()
export class AuthGuard  {

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private consumerService : ConsumerService
    ) { }

    canActivate = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const checkAuthentication = await isAuthenticated();
        if (checkAuthentication) {
            const result = await this.consumerService.loadUser();
            if (result && result.role && (result.role.id === ROLE_TYPE.consumer)) {
                setInStorage('consumer', result);
                return true;
            }
        }
        this.toastr.error("You are not logged in. Please login to continue", "Login Error!");
        this.router.navigate(["/login"]);
        return false;
    };
}

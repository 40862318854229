import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthsPipe } from '../customPipes/months.pipe';

@NgModule({
  declarations: [MonthsPipe],
  imports: [CommonModule],
  providers: [MonthsPipe],
  exports: [MonthsPipe],
})
export class SharedModule {}

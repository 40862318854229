<div class="cons-produ  mt-4 mb-5 d-inline-block w-100">
    <h2 class="font-weight-bold text-dark">{{'Change Password' | translate }} </h2>
  </div>
  <!-- <h5 class="font-weight-bold pl-4 mb-4 text-dark">{{'consumer' | translate }}  {{'data' | translate }} </h5> -->
  
  <div class="cons-part border border-primary text-dark rounded">
    <form class="form-horizontal d-inline-block w-100 p-4" role="form" [formGroup]="changePasswordForm">
      <div class="row">
        <div class="col-12">
  
        <!-- Old password -->
          <div class="form-group row mt-0">
            <label for="oldPassword" class="col-lg-3 text-dark control-label ml-2 ml-md-0 mt-3 mt-md-3">{{'Old' | translate }} {{ 'password' | translate }}</label>
            <div class="col-lg-9 ">
              <input  type="password" formControlName="oldPassword" placeholder="{{'enter' | translate }} {{'password' | translate }} "
                class="form-control border border-primary text-dark col-sm-12 d-inline-block text-dark" 
                 id="oldPassword">
              <div class="text-danger pt-1" *ngIf="
              formControl.oldPassword.touched &&
              formControl.oldPassword.errors?.required">
                <small>{{'Old' | translate }} {{ 'password' | translate }} {{'is required.' | translate }} </small>
              </div>
              <div class="text-danger text-left mt-2"
              *ngIf="formControl.oldPassword.touched && formControl.oldPassword.errors?.pattern">
              <small>
                {{ 'Password should be atleast 8 characters, should contain one uppercase letter, one lowercase letter,one special character and one number.' | translate }}
              </small>
            </div>

            </div>
          </div>
          <!-- New Password -->
          <div class="form-group row mt-0">
            <label for="newPassword" class="col-lg-3 text-dark control-label ml-2 ml-md-0 mt-3 mt-md-3">{{'new password' | translate }}</label>
            <div class="col-lg-9 ">
              <input  type="password" formControlName="newPassword" placeholder="{{'enter' | translate }} {{'new password' | translate }} "
                class="form-control border border-primary text-dark col-sm-12 d-inline-block text-dark" 
                 id="newPassword">
              <div class="text-danger pt-1" *ngIf="
              formControl.newPassword.touched &&
              formControl.newPassword.errors?.required">
                <small>{{'new password' | translate }} {{'is required.' | translate }} </small>
              </div>

              <div class="text-danger text-left mt-2"
              *ngIf="formControl.newPassword.touched && formControl.newPassword.errors?.pattern">
              <small>
                {{ 'Password should be atleast 8 characters, should contain one uppercase letter, one lowercase letter,one special character and one number.' | translate }}
              </small>
            </div>

            </div>
          </div>
          <!-- Confirm password -->
          <div class="form-group row mt-0">
            <label for="first_name" class="col-lg-3 text-dark control-label ml-2 ml-md-0 mt-3 mt-md-3">{{'Confirm' | translate }}  {{'password' | translate }}</label>
            <div class="col-lg-9 ">
              <input  type="password" formControlName="confirmPassword" placeholder="{{'Confirm' | translate }} {{'password' | translate }} "
                class="form-control border border-primary text-dark col-sm-12 d-inline-block text-dark" 
                 id="confirmPassword">
              <div class="text-danger pt-1" *ngIf="
              formControl.confirmPassword.touched &&
              formControl.confirmPassword.errors?.required">
                <small>{{'Confirm' | translate }} {{'password' | translate }}  {{'is required.' | translate }} </small>
              </div>

              <div class="text-danger text-left mt-2"
              *ngIf="formControl.confirmPassword.touched && formControl.confirmPassword.errors?.confirmedValidator">
              <small>
                {{ 'Passwords do not match.' | translate }}
              </small>
            </div>

            </div>
          </div>
        </div>
      </div>
    </form>
    <div class=" d-flex justify-content-end ">
      <div class="row form-group m-3">
        <button type="button"
          class="btn button_form rounded font-size-18 font-weight-bold mt-1 text-white pl-5 pb-3 pr-5 pt-3"
          [disabled]="!changePasswordForm.valid" (click)="changePassword()">{{'save' | translate }}</button>
      </div>
    </div>
  
  </div>
import { Routes } from "@angular/router";
import { ConsumerComponent } from "src/app/pages/consumer/consumer.component";
import { ContractsComponent } from "src/app/pages/contracts/contracts.component";
import { DashboardComponent } from "src/app/pages/dashboard/dashboard.component";
import { ViewContractComponent } from "src/app/pages/view-contract/view-contract.component";
import { ApiComponent } from "../../pages/api/api.component";
import { ProductsComponent } from "../../pages/products/products.component";
import { ApiDocsComponent } from "./../../pages/api-docs/api-docs.component";
import { ChangePasswordComponent } from "../../pages/change-password/change-password.component";
import { LinksComponent } from "src/app/pages/links/links.component";

export const ManagerLayoutRoutes: Routes = [
  { path: "api", component: ApiComponent },
  { path: "products", component: ProductsComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "api-docs", component: ApiDocsComponent },
  { path: "profile", component: ConsumerComponent },
  { path: "products/:id", component: ProductsComponent },
  { path: "contracts", component: ContractsComponent },
  { path: "contracts/:id", component: ViewContractComponent },
  { path: "change-password", component: ChangePasswordComponent },
  { path: "links", component: LinksComponent },
  {
    path: "billing",
    loadChildren: () =>
      import("../../pages/billing/billing.module").then((m) => m.BillingModule),
  },
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
];

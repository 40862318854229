<div class="descr-part">

    <h2 class="text-dark mb-3 font-size-18 font-weight-bold" >{{ 'description' | translate }}</h2>

    <form [formGroup]="detailsForm" class="d-inline-block rounded border border-light p-3">
    <div class="form-horizontal">     
        <div class="form-group w-100 float-left mb-0 mt-4">
            <div class="row pl-3">
                <label class="col-sm-1 col-form-label font-size-18 text-dark mt-0 mt-md-3">EAN</label>
                <div class="col-sm-6 box-size ">
                    <div class="d-flex">
                        <input formControlName="ean" id="ean" type="text" class="form-control text-dark border border-primary " [readonly]="product" [ngClass]="{'disble-box':product}"   placeholder="{{'enter' | translate}} EAN">    
                    </div>
                </div>
            </div> 
        </div>
       
        <div class="form-group w-100 float-left mb-0 mt-4">
            <div class="row pl-3">
                
                <div class="col-lg-6 col-xs-12 pl-0">
                    <label class="col-sm-2 text-dark mt-0 mt-md-3 ellipses col-form-label font-size-18">{{ 'publishing date' | translate}} </label>
                    <div class="col-sm-10 d-flex pl-0 pl-md-3">
                        <input class="form-control  border border-primary text-dark" placeholder="YYYY-MM-DD" name="dp" [readonly]="product" [ngClass]="{'disble-box':product}"  
                            ngbDatepicker  #d="ngbDatepicker"
                            formControlName="date">
                        <img src="../../../assets/images/calu1-icon.png" class="cul-img mb-3 mt-3 ml-5 cursor-pointer">
                        <img ngbPopover="{{'The date when this product will be published on the plateform' | translate}}" triggers="mouseenter:mouseleave" src="../../../assets/images/q-mark.png" class="q-mark ml-4 mt-3" >
                    </div>
                </div>
                    
                <div class="col-lg-6 col-xs-12 pl-0">
                    <label class="col-sm-3 text-dark col-form-label mt-3 font-size-18">{{'enable' | translate }}</label>
                    <div class="col-sm-9">
                        <span class="custom-control mt-2 custom-switch d-inline-block">
                            <input type="checkbox" class="custom-control-input" id="enable"
                                formControlName="enable" [attr.disabled]="true"/>
                        <label class="custom-control-label mt-1 font-size-18" for="enable"></label>
                        </span>
                        <img ngbPopover="{{'This switch enable or desable the availability of the product on the plateform' | translate}}" triggers="mouseenter:mouseleave" src="../../../assets/images/q-mark.png" class="q-mark ml-4 pr-2 pr-md-2">
                    </div>
                </div>
                
            </div>
        </div>
        
        <div class="form-group  w-100 float-left mb-0 mt-4"> 
            <div class="row pl-3">
                <label class="col-sm-1 mt-3 col-form-label text-dark font-size-18">{{ 'title' | translate}}</label>
                <div class="col-sm-11 box-size">
                    <input type="text" class="form-control  border border-primary text-dark" placeholder="{{ 'enter' | translate}} {{ 'title' | translate}}" formControlName="title" [readonly]="product" [ngClass]="{'disble-box':product}">
                </div>
            </div>
        </div>
            
        <div class="form-group  w-100 float-left mb-0 mt-4 mt-md-0">
            <label class="mt-0 mt-md-4 text-dark font-size-18">{{'description' | translate}}</label>
            <textarea class="form-control border border-primary desc-heig  h-100 p-3 text-dark" rows="4" formControlName="description"
                    placeholder="{{'enter' | translate}} {{'description' | translate}}" [readonly]="product" [ngClass]="{'disble-box':product}"></textarea>
        </div>
            
        <div class="form-group w-100 float-left mb-0 mt-4">
            <div class="row pl-3">
                <div class="col-lg-6 col-sm-12 d-flex pl-0  pl-md-0">
                    <label class="col-6 col-md-0 col-lg-3 mt-0 mt-md-3 text-dark col-form-label font-size-18">{{'language' | translate}}</label>
                    <select id="language_id" class="form-control border border-primary border-light text-dark" formControlName="language_id">
                        <option *ngFor="let lan of languages" [value]="lan.id" disabled selected>{{lan.name}}</option>
                    </select>
                    <span class="q-mark  text-light border border-light d-inline-block m-3 pr-2 pr-md-2 pl-2 pl-md-2 rounded-circle text-center position-relative">?</span>
                </div>
                <div class="col-lg-6 col-sm-12 d-flex mt-4 mt-lg-0 pl-0 pl-md-0 ">
                    <label class="mt-0 mt-md-3 text-dark mt-3 mt-md-0 col-form-label font-size-18  d-inline-block">{{ 'manufacturer reference' | translate}}</label>                                   
                        <input type="text" class="form-control border border-primary border-light text-dark" placeholder="{{'enter' | translate}} {{ 'manufacturer reference' | translate}}"
                                formControlName="manufacturer_reference" id="manufacturer_reference" [readonly]="product" [ngClass]="{'disble-box':product}">
                </div>
            </div>
        </div>

        <div class="form-group w-100 float-left mb-0 mt-4">
            <div class="row pl-3">
                <div class="col-lg-6 col-sm-12 d-flex pl-0  pl-md-0">
                    <label class="col-6 col-md-0 col-lg-3 mt-0 mt-md-3 text-dark col-form-label font-size-18">{{'country' | translate}}</label>
                    <input formControlName="country_name" type="text" class="form-control border border-light"
                    id="country_name" [readonly]="product" [ngClass]="{'disble-box':product}">
                        <span class="q-mark text-light border border-light d-inline-block m-3 rounded-circle text-center pr-2 pr-md-2 pl-2 pl-md-2 position-relative">?</span>
                </div>
                <div class="col-lg-6 col-sm-12 d-flex mt-4 mt-lg-0 pl-0 pl-md-0 ">
                    <label class="mt-0 mt-md-3 text-dark mt-3 mt-md-0 col-form-label d-inline-block font-size-18">ASIN</label>                                   
                        <input type="text" class="form-control border border-primary border border-light text-dark" placeholder="{{'enter' | translate}} ASIN"
                        formControlName="asin" maxlength="255" id="asin" [readonly]="product" [ngClass]="{'disble-box':product}">
                </div> 
            </div>
        </div>

        

        <div class="form-group w-100 float-left mb-0 mt-4">
            <div class="row pl-3">
                <div class="col-lg-6 col-sm-12 d-flex  pl-0  pl-md-0">
                    <label class="col-6 col-md-0 col-lg-3 mt-0 mt-md-3 text-dark ellipses col-form-label font-size-18">{{'manufacturer' | translate}} {{'name' | translate}}</label>
                    <input formControlName="manufacturer_name" placeholder=" {{'enter' | translate}} {{'manufacturer' | translate}} {{'name' | translate}}" type="text" class="form-control border border-primary border-light text-dark"
                        id="manufacturer_name" [readonly]="product" [ngClass]="{'disble-box':product}">  
                </div>         
            </div>
        </div>  
    </div>
    </form>
</div>

<!-- New Changes-->
<div class="driv-part mt-5">
    <h2 class="font-weight-bold mb-3 pl-4 text-dark font-size-18">{{'characteristics' | translate}}</h2>
    <div class="driv-tl p-3  rounded border border-primary">
        <div class="responsive">
            <table class="table table-bordered border border-primary rounded">
                <thead>
                    <tr>
                        <th>{{'category' | translate}}</th>
                        <th>{{'description' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let char of caracteristics; let i = index">
                        <td>{{char.caracteristique}}</td>
                        <td>{{char.description}}</td>
                    </tr>
                    <tr *ngIf="caracteristics.length === 0">
                        <td class="border-0"> {{'no' | translate}} {{'characteristics' | translate}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<div class="film-part mt-4">
    <h2 class="font-weight-bold pl-3 text-dark mb-3 font-size-18">Images</h2>
    <div class="film-box  rounded p-4 border border-primary">
        
        <div class="img-pt rounded text-center d-inline-block border border-primary" *ngFor="let file of files| filter: { 'type_id': FileType.image}; let i = index">
            <img [src]="file.url" class="img-wrapper">
        </div> 
        
    </div>
</div>



<div class="film-part mt-4">
    <h2  class="font-weight-bold text-dark pl-3 mb-3 font-size-18">{{'videos' | translate}}</h2>
    <div class="film-box  rounded p-4 border border-primary">
        
        <div class="img-pt  rounded text-center d-inline-block border border-primary" *ngFor="let file of files| filter: { 'type_id': FileType.video}; let i = index">
            <video *ngIf = "!file.external_url" width="320" height="240" controls class="img-wrapper">
                <source [src]="file.url">
            </video>
            <iframe  *ngIf = "file.external_url" [src]="file.external_url" width="120" height="120" frameborder="0" webkitallowfullscreen mozallowfullscreen
      allowfullscreen></iframe>
        </div>
        
</div>




<div class="driv-part mt-5">
    <h2 class="font-weight-bold mb-3 text-dark pl-4 font-size-18">Drivers</h2>
    <div class="driv-tl p-3  rounded border border-primary">
        <div class="responsive">
            <table class="table table-bordered  border border-primary rounded">
                <thead>
                    <tr>
                       <th>{{'description' | translate}}</th>
                       <th>Options</th>
                    </tr>
               </thead>
               
                <tbody>
                    <tr *ngFor="let file of files| filter: { 'type_id': FileType.driver}; let i = index">

                        <td>{{file.description}}</td>
                        <td>

                            <a class="edit-pen" [href]="file?.url? file?.url : file?.buffer_url" download=""
                                target="blank"><img src="../../../assets/images/download.png"></a>
                        </td>
                    </tr>
                    <tr *ngIf="(files | filter: { 'type_id': FileType.driver}).length === 0">
                        <td class="border-0"> {{'no' | translate}} Drivers </td>
                    </tr>
                </tbody>
            
            </table>
        </div>
    </div>
</div>

<div class="driv-part mt-5">
    <h2 class="font-weight-bold text-dark mb-3 pl-4 font-size-18">Documents</h2>
    <div class="driv-tl p-3  rounded border border-primary">
        <div class="responsive">
            <table class="table table-bordered border border-primary rounded">
                <thead>
                    <tr> 
                        <th>{{'description' | translate}}</th> 
                        <th>Options</th>
                    
                    </tr>
               </thead>
               
                <tbody>
                    <tr *ngFor="let file of files| filter: { 'type_id': FileType.document}; let i = index">
                        <td>{{file.description}}</td>
                        <td>

                            <a class="edit-pen" [href]="file?.url" download=""
                                target="blank"><img src="../../../assets/images/download.png"></a>

                        </td>
                    </tr>
                    <tr *ngIf="(files | filter: { 'type_id': FileType.document}).length === 0">
                        <td class="border-0"> {{'no' | translate}} Documents </td>
                    </tr>
                </tbody>
                
            </table>
        </div>
    </div>
</div>


<div class="driv-part mt-5">
    <h2 class="font-weight-bold mb-3 pl-4 text-dark font-size-18">{{'links' | translate}}</h2>
    <div class="driv-tl p-3  rounded border border-primary">
        <div class="responsive">
            <table class="table table-bordered border border-primary rounded">
                <thead>
                    <tr>
                        <th>{{'links' | translate}}</th>
                        <th>Options</th>
                    </tr>
                </thead>
                
                <tbody>
                  <tr *ngFor="let li of links; let i = index">
                        <td>{{li?.link}}</td>
                        <td>  
                            <a class="edit-pen cursor-pointer" (click)="editLink(i)">
                                    <img
                                    src="../../../assets/images/edit-icon.png"></a>
                            <a (click)="removeLink(i)" class="cursor-pointer"><img src="../../../assets/images/delete-icon.png"></a>
                        </td>
                    </tr>
                    <tr *ngIf="links?.length === 0" >
                        <td colspan="2"> {{'no' | translate}} {{'link' | translate}}</td>
                    </tr>
                </tbody>
            
            </table>
        </div>
    
     <form [formGroup]="linkForm"  class="driv-form">
        <div class="form-horizontal">
            <div class="form-group  mb-0 mt-4">
                <div class="row pl-2">
                    <label class="col-sm-2 mt-0 mt-md-3 ml-2 ml-md-0 col-form-label text-dark font-size-18  w-100 pl-2 pl-md-4 pb-1 ">{{'link' | translate}} URL</label>
                    <div class="col-lg-5">
                        
                        <input type="text" class="form-control text-dark border border-primary" 
                        placeholder="{{'enter' | translate}} {{'link' | translate}}"
                        [(ngModel)]="activeDocs.linkDetail.link" id="activeDocs?.linkDetail?.link" formControlName="linkUrl">
                        
                        <div class="text-danger pt-1" *ngIf="
                            formControl('linkForm').linkUrl?.touched &&
                            formControl('linkForm').linkUrl.errors?.required">
                            <small>{{'link' | translate}} URL {{'is required.' | translate}}</small>
                        </div>
                        <div class="text-danger pt-1" *ngIf="
                            formControl('linkForm').linkUrl?.touched &&
                            formControl('linkForm').linkUrl.errors?.pattern">
                            <small>{{'invalid' | translate}} URL. </small>
                        </div> 
                    </div>
                    <div class="col-lg-5 file-add-nt position-relative">
                        <button class = "btn btn-primary mt-3 mt-md-0 float-right position-relative" [disabled]="!linkForm.valid" (click)="addLinks()">{{ (activeEditLink >= 0 ? 'save' : 'add') | translate  }}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    </div>
</div>

<div class="driv-part btn-div mt-5"> 
    <div class="driv-tl p-0 border-0">
        <form class="driv-form">
            <div class="form-horizontal">
                <div class="form-group">
                    <div class="mt-3 d-flex justify-content-end">                
                        <button type="button" class="font-weight-bold font-size-18 rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
                        (click)="doCancel()"  >{{'cancel' | translate}}</button>
                        <button type="button" class="save-product-btn  font-size-18  border rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
                        (click)="editProduct()">{{'save' | translate}}</button>
            
                    </div>
                </div>
            </div>

        </form>
    </div>

</div>




import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VALIDATION_REGEX, SUCCESS_MESSAGES, ERROR_MESSAGES } from '../../common/constants';
import { ConfirmedValidator } from 'src/app/common/customValidators/passwordMatch';
import { resetPasswords } from '../../services/auth/auth.service';
import { ConsumerService } from '../../services/consumer.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm : FormGroup;

  constructor(
    
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private consumerService: ConsumerService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.initChangePasswordForm();
    this.consumerService.switchLn.subscribe((ln: any) => {
      console.log('inside ln sub', ln);
      if(ln && ln.ln) this.translate.use(ln.ln);
    })
  }


  initChangePasswordForm(){
    this.changePasswordForm = this.formBuilder.group({
      oldPassword : ['', [Validators.required, Validators.pattern(VALIDATION_REGEX.PASSWORD)]],
      newPassword : ['', [Validators.required, Validators.pattern(VALIDATION_REGEX.PASSWORD)]],
      confirmPassword : ['', [Validators.required]]
    }, 
    {
      validator: ConfirmedValidator("newPassword", "confirmPassword"),
    })
  }

  get formControl() {
    return this.changePasswordForm.controls;
  }

  changePassword = async() => {
    let { oldPassword, newPassword } = this.changePasswordForm.value;
    this.spinner.show();
    try{
      await resetPasswords( oldPassword, newPassword);
      this.toastr.success(SUCCESS_MESSAGES.PASSWORD_CHANGED_SUCCESSFULLY);
      this.changePasswordForm.reset(this.initChangePasswordForm);
    }
    catch (err) {
      this.toastr.error(err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

}

import { Component } from '@angular/core';
import Amplify from "aws-amplify";
import { environment } from "src/environments/environment";
import { NgcCookieConsentService } from "ngx-cookieconsent";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Describy.com – PLUG';

  constructor(private ccService: NgcCookieConsentService) {
    Amplify.configure(environment.amplify);
  }
}

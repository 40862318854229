<div role="document">
  <div class="modal-content">

    <!-- modal header -->
    <div class="modal-header bg-primary ">
      <h2 class="modal-title text-white">Add Link</h2>
    </div>
    <!-- modal header -->
    <div class="modal-body">
      <!-- modal content -->
      <form [formGroup]="addLinkForm" class="driv-form">
        <div class="form-horizontal">
          <div class="form-group  mb-0 mt-1">
            <div class="row pl-2">
              <label
                class="col-sm-3 ml-2 ml-md-0 col-form-label text-dark font-size-18  w-100 pl-2 pl-md-4 pb-1 ">{{'link' | translate}}
                URL<sup>*</sup></label>
              <div class="col-lg-9">

                <input type="text" class="form-control text-dark border border-primary"
                  placeholder="{{'enter' | translate}} {{'link' | translate}}" id="activeDocs?.linkDetail?.link"
                  formControlName="link">

                <div class="text-danger pt-1" *ngIf="
                                    addLinkForm.controls.link?.touched &&
                                    addLinkForm.controls.link.errors?.required">
                  <small>{{'link' | translate}} URL {{'is required.' | translate}}</small>
                </div>
                <div class="text-danger pt-1" *ngIf="
                                    addLinkForm.controls.link?.touched &&
                                    addLinkForm.controls.link.errors?.pattern">
                  <small>{{'invalid' | translate}} URL. </small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group  mb-0 mt-4">
            <div class="row pl-2">
              <label
                class="col-sm-3  ml-md-0 col-form-label text-dark font-size-18  w-100 pl-2 pl-md-4 pb-1 ">{{'Product' | translate}}<sup>*</sup></label>
              <div class="col-lg-9">
               <input type="text" class="form-control text-dark border border-primary"
                  placeholder="{{'enter' | translate}} {{'product' | translate}} {{'url' | translate}}" id="activeDocs?.linkDetail?.link"
                  formControlName="product_url">
                <div class="text-danger pt-1" *ngIf="addLinkForm.controls.product_url?.touched &&
                addLinkForm.controls.product_url.errors?.required">
                  <small>Please enter Product url</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- modal content -->

    </div>
    <div class="modal-footer d-flex justify-content-between">

      <button type="button" class="btn btn-primary" (click)="activeModal.close(false)">CANCEL</button>
      <button type="button" class="btn btn-primary" [disabled]="isSubmitted"
        (click)="addLink()">{{'Add Link' | translate}}</button>


    </div>
  </div>
</div>

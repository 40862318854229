// core modules
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { VERSION } from 'src/environments/version';
import {TranslateService} from '@ngx-translate/core';
import { ConsumerService } from '../../services/consumer.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  fontIcon?: string;

}
export const ROUTES: RouteInfo[] = [
  {
    path: "dashboard",
    title: "dashboard",
    icon: "pie-chart.svg",
    class: "",
  },
  {
    path: "products",
    title: "products",
    icon: "star.svg",
    class: "",
  },
  {
    path: "links",
    title: "links",
    icon: "link.svg",
    class: "",
  },
  {
    path: "profile",
    title: "consumer",
    icon: "consumer.svg",
    class: "",
  },
  {
    path: "api",
    title: "API",
    icon: "api.svg",
    class: "",
  },
  {
    path: "api-docs",
    title: "API Doc",
    icon: "api-docs.svg",
    class: "",
  },
  {
    path: "contracts",
    title: "Contracts",
    icon: "contract.svg",
    class: "",
  },
  {
    path: "billing",
    title: "Invoices",
    icon: "",
    fontIcon: "fas fa-file-invoice-dollar",
    class: "",
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent implements OnInit {
  public menuItems: any[];
  public ver = VERSION.version;

  @Input() showSideNav: boolean;
  constructor(private router: Router,
    private consumerService: ConsumerService,
    private translate: TranslateService,

    ) { }

  ngOnInit(): void {

    this.consumerService.switchLn.subscribe((ln: any) => {
      console.log('inside sidebar ln sub', ln);
      if(ln && ln.ln) this.translate.use(ln.ln);
    })

    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
}
